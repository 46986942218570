// import { getFormatedStorageUsed } from "#client/common/utils.ts";

import type { ObjectValues } from "@es/core/types";
import { UploadLimitType } from "@es/mongodb/constants";

export const Tiers = {
  free: {
    bestValue: false,
    description: "No commitments. Try every feature before you buy.",
    features: (args: {
      storageCap: number;
      limit: { count: number; kind: ObjectValues<typeof UploadLimitType> };
    }) => {
      return [
        args.limit.kind === UploadLimitType.ITEM
          ? `Up to ${args.limit.count.toLocaleString()} photos or 1 video`
          : `Up to ${args.limit.count.toLocaleString()} guests`,
        "Unlimited guests",
        "Audio, video and written guestbook entries",
        "Upload and storage for 2 weeks",
        "Live image and video slideshow",
      ];
    },
  },
  plus: {
    description:
      "Perfect for smaller events that don't need a ton of video uploads.",
    features: (args: {
      storageCap: number;
      limit: { count: number; kind: ObjectValues<typeof UploadLimitType> };
    }) => {
      return [
        args.limit.kind === UploadLimitType.ITEM
          ? `Up to ${args.limit.count.toLocaleString()} photos & 1hr of video`
          : `Up to ${args.limit.count.toLocaleString()} guests`,
        "Unlimited guests",
        "Audio, video and written guestbook entries",
        "Upload and storage for 12 months",
        "Live image and video slideshow",
        // "Two years to create the gallery from date of purchase",
      ];
    },
    bestValue: false,
  },
  premium: {
    description:
      "The worry-free solution. Collect every photo & video your guests will take.",
    features: (args: {
      storageCap: number;
      limit: { count: number; kind: ObjectValues<typeof UploadLimitType> };
    }) => {
      return [
        args.limit.kind === UploadLimitType.ITEM
          ? `Up to ${args.limit.count.toLocaleString()} photos & 2hr of video`
          : `Up to ${args.limit.count.toLocaleString()} guests`,
        "Unlimited guests",
        "Audio, video and written guestbook entries",
        "Upload and storage for 12 months",
        "Live image and video slideshow",
        // "Two years to create the gallery from date of purchase",
      ];
    },
    bestValue: true,
  },
};
